
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$testing-primary: mat-palette($mat-indigo);
$testing-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$testing-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$testing-theme: mat-light-theme((
  color: (
    primary: $testing-primary,
    accent: $testing-accent,
    warn: $testing-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($testing-theme);


@import '../src/app/styles/mixin';
@import '../src/app/styles/variables';

.filled-button {
  @include button-filled($color-accent-primary, $color-terciary);
}

.outlined-button {
  @include button-outlined($color-accent-primary, $color-accent-primary);
}


:root {
  --primaryColor: #b7bf28;
  --whiteColor: #FFFFFF;
  --blackColor: #000000;
  --backgroundColorApp: #090909;
  --borderCards: rgba(123, 129, 0, 0.451);
  --backgroundButtonThird: #202020;
  --backgroundCardItemLogin: #0D0D0D;
  --backgroundModals: #131313;
  --colorIconFavoriteCard: #4F4F4F;
  --textGreyColor: #8A8A8A;
  --backgroundCardEvents: #202020;
  --backgroundSectionPurchaseReservation: #0C0C0CCC;
  --backgroundInformationPurchaseReservation: #050505;
  --textColorPurchase: #585858;
  --dividerColor: #1E1E1E;
}

h4{
  font-size: 120px
}

body {
  background-color: var(--backgroundColorApp) !important;
}




/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';



/* Custom styles for the info window */
.info-window {
  background-color: white !important;
  border: 1px solid #ccc;
  padding: 10px;
  max-width: 300px;
  outline: none !important;
}

.green-button-marker {
  background-color: #b7bf28;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: normal;
  padding: 5px 10px;
  cursor: pointer;
}

.custom-marker {
  //background-color: #2c75ff;
  //color: #fff;
  //color: #2c75ff;
  color: black;
  //border: 1px solid #2c75ff;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  text-align: center;
  width: auto; /* Set the desired width */
  height: auto; /* Set the desired height */
  font-size: 90%;
  font-weight: bold;
}

.custom-marker-shape {
  //background-color: #2c75ff;
  //color: #fff;
  color: black;
  //border: 1px solid #2c75ff;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  text-align: center;
  width: auto; /* Set the desired width */
  height: auto; /* Set the desired height */
  font-size: 100%;
  font-weight: bold;
}


.swal2-container {
  z-index: 100000 !important; /* Set your desired z-index value */
}

.checkBox {
  display: inline-block;
  margin-left: -60px;
  margin-right: 20px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  border: 3px solid #b7bf28;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.checkBox div {
  width: 60px;
  height: 60px;
  background-color: #b7bf28;
  top: -52px;
  left: -52px;
  position: absolute;
  transform: rotateZ(45deg);
  z-index: 100;
}

.checkBox input[type=checkbox]:checked + div {
  left: -10px;
  top: -10px;
}

.checkBox input[type=checkbox] {
  position: absolute;
  left: 50px;
  visibility: hidden;
}




.modal-content-asiento {
  position: relative;
  max-width: 350px;
  margin: 0px;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  animation: scaleUp 0.0s ease-in-out 0s forwards;
}

.ticket-container {
  border: 4px solid lightgray;
  width: 100%;
  text-align: center;
  padding-top: 10px;
}

.ticket-header-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  color: #b5bc43;
}

.ticket-data-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  color: black;
}

.ticket-header-separator {
  height: 3px;
  width: 91%;
  background-color: lightgray;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 15px;
}

.ticket-type-select {
  background-color: #b5bc43;
  color: white;
  text-align: center;
  border-radius: 5px;
  height: 20px;
}

.ticket-button {
  background-color: #b5bc43;
  color: white;
  border-radius: 10px;
  width: 180px;
  height: 30px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: bold;
}

.custom-modal .modal-content {
  background-color: transparent;
  border-radius: 10px;
  width: 100%;
  border: none;
  /* Add more styles as needed */
}

// SWAL ALERTS

/* styles.scss */

.swal2-popup {
  background: #131313 !important;
  color: #FFFFFF !important;
}
.swal2-html-container {
  display: flex !important;
  flex-direction: column !important;
}
.swal2-title,
.swal2-content {
  color: #FFFFFF !important;
}
.swal2-confirm {
  background: #131313 !important;
  border: 2px solid #b7bf28 !important;
  color: #FFFFFF !important;
}
.swal2-confirm:hover {
  background: #b7bf28 !important;
  color: #131313 !important;
}
/*  MODAL TRANSFERIR BOLETOS  */
.modal_transfer .swal2-modal {
  width: 70%;
}
.content_modal_transfer {
  .title_transfer {
    font-size: 30px;
    color: var(--primaryColor);
  }
  .description_transfer {
    font-size: 16px;
  }
  .section_event_info {
    .title_event {
      color: var(--whiteColor);
    }
  }
  .dates_event {
    display: flex;
    .item {
      flex: 1;
      display: flex;
    }
    .item_dates {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 5px;
      .dates {
        display: flex;
        gap: 8px;
        .title {
          margin: 0;
        }
      }
    }
  }
  .section_transfer_text {
    .title_transfer {
      font-size: 30px;
      color: var(--whiteColor);
    }
    .description_transfer {
      font-size: 16px;
      color: var(--primaryColor);
    }
  }
  .seciton_tickets {
    .header_tickets {
      display: flex;
      justify-content: space-between;
      .section_list {
        flex: 1;
      }
      .section_fila {
        flex: 1;
      }
      .section_asiento {
        flex: 1;
      }
      .section_type_ticket {
        flex: 1;
      }
    }
    .tickets {
      display: flex;
      .section_list {
        flex: 1;
      }
      .section_fila {
        flex: 1;
      }
      .section_asiento {
        flex: 1;
      }
      .section_type_ticket {
        flex: 1;
      }
    }
  }
  .form_transfer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
    .form_group {
      display: flex;
      flex-direction: column;
      gap: 7px;
      align-items: start;
      label {
        font-size: 16px;
        font-weight: 500;
      }
      input {
        height: 40px;
        border-radius: 10px;
        padding: 10px;
      }
    }
  }
}


// TEXTS
.title_cards_event {
  color: var(--whiteColor);
  font-size: 25px;
  font-weight: 700;
}
.description_cards_event {
  color: var(--whiteColor);
  font-size: 15px;
  font-weight: 400;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 37px;
  margin: 10 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.bg_input {
  background: #D9D9D9;
}


// BUTTONS
.btn {
  border-radius: 14px !important;
  cursor: pointer !important;
  padding: 15px 20px !important;
}
.primary_button {
  background-color: var(--primaryColor) !important;
  font-weight: bold !important;
  &:hover {
    color: var(--whiteColor);
  }
}
.secondary_button {
  border: 1px solid var(--primaryColor) !important;
  color: var(--primaryColor) !important;
  &:hover {
    background-color: var(--primaryColor) !important;
    color: var(--whiteColor) !important;
  }
}
.third_button {
  background-color: var(--backgroundButtonThird) !important;
  color: var(--whiteColor) !important;
  &:hover {
    background-color: var(--primaryColor) !important;
  }
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.divider {
  border-color: var(--dividerColor);
  border-width: 3px;
  margin: 10px;
}

// Icons

.favorite-heart-filled {
  color: var(--primaryColor) !important;
}


// RESPONSIVE

@media screen and (max-width: 767px) {
  // Purchase
  .content_purchases {
    .section_reservations {
      .item_reservations {
        flex-direction: column;
        height: 550px !important;
      }      
    }
  }

  // PurchaseDetail
  .content_purchase_detail {
    .reservation_content {
      flex-direction: column;
      .img_event {
        min-height: 300px;
      }
    }
  }
}
